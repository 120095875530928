<template>
	<form
		@submit.prevent="changePasswordSubmit"
		@keydown="clearError($event.target.name);">
		<input-text
			id="new_password"
			:label="translate('new_password')"
			:setter-value="form.new_password"
			:errors="errors['new_password'] || errors['password']"
			:required="true"
			:type="requireLoginPassword ? 'password' : 'prepend_password'"
			icon="icon-lock"
			@dataChanged="form.new_password = $event" />

		<input-text
			id="new_password_confirmation"
			:label="translate('new_password_confirmation')"
			:setter-value="form.new_password_confirmation"
			:errors="errors['new_password_confirmation']"
			:required="true"
			:type="requireLoginPassword ? 'password' : 'prepend_password'"
			icon="icon-lock"
			@dataChanged="form.new_password_confirmation = $event" />

		<input-text
			v-if="requireLoginPassword"
			id="password"
			:label="translate('current_password')"
			:setter-value="form.password"
			:errors="errors['password']"
			:required="true"
			type="password"
			@dataChanged="form.password = $event" />

		<button
			:disabled="Object.keys(errors).length > 0"
			:class="!$user.auth() ? 'btn-block': ''"
			class="btn btn-primary float-right">
			{{ submitLabel ? submitLabel : translate('submit') }}
		</button>
	</form>
</template>
<script>

import InputText from '@/components/InputText';
import { Profile } from '@/translations';

export default {
	name: 'ChangePasswordForm',
	messages: [Profile],
	components: {
		InputText,
	},
	props: {
		errorsImport: {
			type: Object,
			default() {
				return {};
			},
		},
		requireLoginPassword: {
			type: Boolean,
			default: true,
		},
		submitLabel: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			form: {
				password: '',
				new_password: '',
				new_password_confirmation: '',
			},
			errors: this.errorsImport,
		};
	},
	watch: {
		errorsImport(value) {
			this.errors = value;
		},
	},
	methods: {
		changePasswordSubmit() {
			if (!this.requireLoginPassword) {
				delete this.form.password;
			}
			this.$emit('requestChangePassword', this.form);
		},
		hasError(field) {
			if (typeof this.errors[field] !== 'undefined') { return true; }
			return false;
		},
		clearError(field) {
			if (field) {
				if (field === 'new_password') {
					delete this.errors.password;
				}
				delete this.errors[field];
				this.errors = { ...this.errors };
			}
		},
	},
};
</script>
