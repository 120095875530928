import {
	FORGOT_PASSWORD,
	RESET_PASSWORD,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class User {
	constructor() {
		const axios = new Req();
		this.data = axios;
		this.errors = axios.errors;
		this.loading = axios.loading;
	}

	clear() {
		this.data.clear();
	}

	forgotPassword(payload) {
		const { method, endpoint } = FORGOT_PASSWORD;
		return this.data[method](endpoint, payload).then((response) => response).catch((errors) => Promise.reject(errors));
	}

	resetPassword(encryptedUrl, payload) {
		const { method, endpoint } = RESET_PASSWORD;
		return this.data[method](endpoint(encryptedUrl), payload).then((response) => response).catch((errors) => Promise.reject(errors));
	}
}

export default User;
