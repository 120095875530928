<template>
	<div>
		<is-loading
			id="loading-pass"
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="true" />
		<div v-show="!loading">
			<forgot-password-form
				v-if="!hasFilters"
				:submit-label="translate('reset_password_title')"
				:errors-import="errors.all()"
				@requestResetPassword="requestResetPassword" />
			<change-password-form
				v-if="hasFilters"
				:submit-label="translate('set_new_password')"
				:errors-import="errors.all()"
				:require-login-password="false"
				@requestChangePassword="requestChangePassword" />
			<div
				v-if="errors.messageError"
				class="alert alert-danger my-3"
				role="alert">
				{{ translate('expired_token') }}
			</div>
		</div>
	</div>
</template>
<script>
/* eslint arrow-parens: ["error", "always"] */

// Libraries
import Vue from 'vue';

// Components
import ChangePasswordForm from '@/views/Users/components/ChangePasswordForm';
import isLoading from '@/components/Loading';
import ForgotPasswordForm from '@/views/Users/components/ForgotPasswordForm';

// Mixins
import Captcha from '@/mixins/Captcha';
import DocumentTitle from '@/mixins/DocumentTitle';

// Classes
import ForgotPassword from '@/util/ForgotPassword';

// Translations
import { Validations, Auth } from '@/translations';

Vue.component('isLoading', isLoading);

export default {
	name: 'ForgotPassword',
	messages: [Auth, Validations],
	components: {
		ForgotPasswordForm,
		ChangePasswordForm,
	},
	mixins: [Captcha, DocumentTitle],
	data() {
		return {
			form: new this.$Form({}),
			password: new ForgotPassword(),
			alert: new this.$Alert(),
			loading: false,
		};
	},
	computed: {
		errors() {
			try {
				return this.password.errors;
			} catch (error) {
				return {};
			}
		},
		hasFilters() {
			const { query } = this.$route;
			return !!Object.keys(query).length;
		},
	},
	mounted() {
		if (this.hasFilters) {
			this.setFocus('new_password');
		} else if (!this.hasFilters) {
			this.setFocus('username');
		}
	},
	methods: {
		requestResetPassword(payload) {
			this.loading = true;
			this.getCaptchaToken('reset_password').then((response) => {
				payload['g-recaptcha-response'] = response;
				this.password.forgotPassword(payload).then(() => {
					this.alert.toast('success', this.translate('forgot_password'), {
						position: 'top-end',
						showConfirmButton: false,
						timer: 8000,
					});
					this.$router.push({ name: 'Login' });
				}).catch(() => {
					this.form = new this.$Form(payload);
				}).finally(() => { this.loading = false; });
			});
		},
		requestChangePassword(payload) {
			this.loading = true;
			this.getCaptchaToken('change_password').then((response) => {
				payload['g-recaptcha-response'] = response;
				const query = Object.keys(this.$route.query).map((item) => `${item}=${this.$route.query[item]}`).join('&');
				this.password.resetPassword(`?${query}`, payload).then(() => {
					this.alert.toast('success', this.translate('reset_password'), {
						position: 'top-end',
						showConfirmButton: false,
						timer: 8000,
					});
					this.$router.push({ name: 'Login' });
				}).catch(() => {
					this.form = new this.$Form(payload);
				}).finally(() => { this.loading = false; });
			});
		},
	},
};
</script>
